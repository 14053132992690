<template>
  <div class="merchantCenterList MERCH">
    <!-- 供应商中心 -->
    <div class="merch-card">
      <!-- 搜索列 -->
      <div class="merch-search">
        <el-form ref="searchParams" :model="searchParams" :inline="true">
          <el-form-item prop="complainant">
            <el-input
              maxlength="32"
              v-model="searchParams.complainant"
              placeholder="请输入投诉人"
              clearable
              @keyup.enter.native="handleCurrentChange"
            />
          </el-form-item>
          <el-form-item prop="supplier">
            <el-input
              maxlength="32"
              v-model="searchParams.supplier"
              placeholder="请输入投诉供应商"
              clearable
              @keyup.enter.native="handleCurrentChange"
            />
          </el-form-item>
          <el-form-item prop="complaint">
            <el-input
              maxlength="32"
              v-model="searchParams.complaint"
              placeholder="请输入投诉对象"
              clearable
              @keyup.enter.native="handleCurrentChange"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleCurrentChange"
              >查 询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 列表 -->
      <div
        class="merch-table scorr-roll"
        :style="`max-height: ` + mainHright + `px`"
      >
        <div>
          <el-table :data="searchListData" border style="width: 100%">
            <el-table-column prop="id" label="序号" align="center" width="60"></el-table-column>
            <el-table-column prop="complainant" label="投诉人" align="center"></el-table-column>
            <el-table-column prop="telephone" label="联系电话" align="center"></el-table-column>
            <el-table-column prop="createTime" label="投诉时间" align="center"></el-table-column>
            <el-table-column prop="supplier" label="供应商" align="center"></el-table-column>
            <el-table-column prop="complaint" label="投诉对象" align="center"></el-table-column>
            <el-table-column prop="mobile" label="投诉对象联系电话" align="center"></el-table-column>
            <el-table-column prop="complaintContent" label="投诉内容" align="center" min-width="110">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.complaintContent"
                  placement="bottom"
                  :disabled ="scope.row.complaintContent.length > 30 ? false : true"
                >
                  <div>{{ scope.row.complaintContent.length > 30 ? scope.row.complaintContent.slice(0,30)+'...': scope.row.complaintContent }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          background
          @current-change="searchBusinessList"
          layout="total, prev, pager, next, jumper"
          :total="total"
          :page-size=10
          :current-page="current_page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
export default {
  data() {
    return {
        total:0,
        current_page:null,
        searchParams:{
            page:1,
            perPage:10,
            complainant:'',
            supplier:'',
            complaint:'',
        },
        searchListData:[]
    }
  },
  computed: {
    ...commonIndex.mapState([ "mainHright", ]),
    ...merchantCenterList.mapState([ "page"]),
  },
  created(){
    this.getSearchList()
  },
  methods: {
    ...merchantCenterList.mapActions([
      "getComplainsRecords",
    ]),
    async getSearchList(){
      let {data} = await this.getComplainsRecords(this.searchParams)
      this.searchListData = data.data
      this.current_page = data.currentPage
      this.total = data.total
    },
    //当前页
    searchBusinessList(e) {
      if (e != undefined) {
        this.searchParams.page = e;
      }
      this.getSearchList()
    },
    //搜索
    handleCurrentChange() {
      this.searchParams.page = 1;
      this.getSearchList()
    },
  }
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
    //   margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.el-select{
  width: 325px;
}
.el-button--default{
  background-color:rgba($color: #2E3238, $alpha: 0.05) ;
  color: #383B46 ;
  border: none;
}

</style>
